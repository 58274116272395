<template>
    <div class="AgentCashOut">
        <van-notice-bar left-icon="info-o" v-if="false" @click="showShiming = true">
            你还未进行实名制认证>
        </van-notice-bar>

        <van-cell-group>
            <van-cell title="佣金余额" style="padding-top: 2rem;">
                <template #label>
                    <div class="price"><span>¥</span>{{ pageData.allcash }}</div>
                    <div class="note">*暂不支持分批次提现操作</div>
                </template>
            </van-cell>
            <van-cell title="可提现金额：">
                <template #default>
                    <div style="width: 100%;text-align: left;">¥ {{ pageData.cancash }}</div>
                </template>
            </van-cell>
            <van-cell title="手续费：">
                <template #default>
                    <div style="width: 100%;text-align: left;">¥ {{ pageData.draw_fee }}</div>
                </template>
            </van-cell>
            <van-cell title="到账金额：">
                <template #default>
                    <div style="width: 100%;text-align: left; font-size: 18px;color: red;font-weight: 700;">¥ {{ pageData.withdraw }}</div>
                </template>
            </van-cell>
             <van-cell title="提现到">
                <template #default>
                    <div style="width: 100%;text-align: left;display: flex; align-item:center;">
                        <van-icon name="alipay" color="#1677ff" size="24px" /> 支付宝
                    </div>
                </template>
            </van-cell>
        </van-cell-group>

        <div class="bottom-button">
            <van-button block round type="danger" @click="goCash">确认提现</van-button>
        </div>
        <div class="tongyi">
            <van-icon name="checked" color="#07c160" />我已阅读并同意 <span @click="showXieyi = true">《共享经济合作伙伴协议》</span>
        </div>

        <van-cell-group>
            <van-cell title="提现说明">
                <template #label>
                    <div class="tixianshuom" v-html="pageData.draw_notice"></div>
                </template>
            </van-cell>
        </van-cell-group>

        <van-popup v-model="showXieyi" closeable close-icon="close" position="bottom" :style="{ height: '70%' }" round>
            <div class="showServiceDiv">
                <div class="showServiceDiv_li">共享经济合作伙伴协议</div>
                <div class="showServiceDiv_content" v-html="pageData.corporation"></div>
            </div>
        </van-popup>

        <van-popup v-model="showShiming" closeable close-icon="close" position="bottom" :style="{ height: '70%' }" round>
            <div class="showServiceDiv">
                <div class="showServiceDiv_li">实名认证</div>
                <div class="tongyi">实名认证后才能保证资金安全及时到账</div>
                <div class="showServiceDiv_content">
                    <van-form @submit="onSubmit">
                        <van-field
                            v-model="username"
                            name="username"
                            label="真实姓名"
                            placeholder="真实姓名"
                            :rules="[{ required: true, message: '请填写真实姓名' }]"
                            maxlength="10"
                        />
                        <van-field
                            v-model="idnumber"
                            name="idnumber"
                            label="身份证号"
                            placeholder="身份证号"
                            :rules="[
                                { required: true, message: '请填写身份证号' },
                                { pattern: /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i, message: '请填写正确的身份证号' }
                            ]"
                            maxlength="18"
                        />
                        <div style="margin: 16px;">
                            <van-button round block type="info" native-type="submit">
                                提交认证资料
                            </van-button>
                        </div>
                    </van-form>
                </div>
            </div>
        </van-popup>

        <!-- 填写提现信息 -->
        <van-popup v-model="showCancashInfo" closeable close-icon="close" position="bottom" :style="{ height: '70%' }" round>
            <div class="showServiceDiv">
                <div class="showServiceDiv_li">提交支付信息</div>
                <div class="showServiceDiv_content">
                    <van-form @submit="onSubmitCancash">
                        <van-field
                            v-model.trim="username"
                            name="username"
                            label="真实姓名"
                            placeholder="真实姓名"
                            :rules="[{ required: true, message: '请填写真实姓名' }]"
                            maxlength="10"
                        />
                        <van-field
                            v-model.trim="account"
                            name="account"
                            label="支付宝账号"
                            placeholder="支付宝账号"
                            :rules="[
                                { required: true, message: '请填写支付宝账号' },
                            ]"
                            maxlength="100"
                        />
                        <div style="margin: 16px;">
                            <van-button round block type="info" native-type="submit">
                                提现
                            </van-button>
                        </div>
                    </van-form>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
export default {
    name: "AgentCashOut",
    data() {
        return {
            showXieyi: false,
            showShiming: false,
            username: "",
            idnumber: "",
            account: "",
            showCancashInfo: false,

            pageData: {
                cancash: "0.00",
                allcash: "0.00",
                draw_fee: "0.00",
                withdraw: "0.00"
            }
        };
    },
    methods: {
        // 点击提现
        async goCash() {
            if (this.pageData.cancash < this.pageData.min_limit) {
                this.$toast.fail(`提现金额不足${this.pageData.min_limit}元，多赚点再来吧!`);
                return;
            }
            try {
                await this.$dialog.confirm({
                    title: "提现",
                    message: "确定要提现 ¥" + this.pageData.cancash,
                });
                this.onCash();
            } catch (error) {}
        },
        // 点击确定提现
        onCash() {
            this.showCancashInfo = true;
        },
        // 提交提现
        async onSubmitCancash() {
            const toast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: "正在提交....",
            });

            const res = await axios.post("/withdraw", {
                money: parseFloat(this.pageData.cancash),
                account: this.account,
                user_name: this.username
            })
            if (res.code != 1) {
                this.$toast(res.message);
            } else {
                toast.clear();
                await this.$dialog.alert({
                    title: "提现申请已提交，请等待审核！",
                });
                location.reload();
            }
        },
        onSubmit(subData) {
            if (!subData.username || !subData.idnumber) {
                this.$toast.fail("请填写完整真实的资料");
                return;
            }

            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: "正在提交....",
            });

            axios
                .post("/shiming", subData)
                .then(response => {
                    const res = response.data;
                    if (res.status === 1) {
                        this.$toast.success("认证成功");
                    } else {
                        this.$toast(res.message ? res.message : "认证失败，请稍后再试.");
                    }
                    let second = 2;
                    const timer = setInterval(() => {
                        second--;
                        if (second < 0) {
                            clearInterval(timer);
                            this.$toast.clear();
                            location.reload();
                        }
                    }, 1000);
                })
                .catch(error => {
                    console.log(error);
                });

            this.$toast.clear();
        },
    },
    async created() {
        const res = await axios.get("/withdrawhome");
        this.pageData = res.data;
    },
};
</script>

<style scoped>
.price {
    font-size: 3rem;
    font-weight: 700;
    color: #000;
    display: block;
    margin: 2rem 1rem;
}
.price > span {
    font-size: 2rem;
    padding-right: 10px;
}
.note {
    font-size: 10px;
    color: #ccc;
    display: block;
    margin: 2rem 1rem;
}
.bottom-button {
    width: 70%;
    text-align: center;
    display: block;
    margin: 0 auto;
    padding-top: 2rem;
}
.tixianshuom > div {
    font-size: 12px;
    color: #ccc;
}
.tongyi {
    width: 100%;
    margin: auto 0;
    font-size: 10px;
    padding-top: 10px;
    color: #ccc;
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tongyi > span {
    color: #f39a0d;
}
.showServiceDiv {
    margin: 2rem 1rem 1rem 1rem;
    text-align: center;
}
.showServiceDiv_li {
    font-size: 1rem;
    font-weight: bold;
    margin: 0.5rem 0rem;
}
.showServiceDiv_content {
    text-align: left;
    font-size: 12px;
}
</style>
